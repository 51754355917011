
import React from "react";
//import { Button, Card, Row, Col, Container, Navbar, Nav  } from 'react-bootstrap'

export default function BootstrapPage() {
  return (
    <div class="container">

      {/* Start Row 5 */}

      <div class="row">
        <div class="col-3 mt-2">
          <img src="img/room1.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room2.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room3.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room4.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room1.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room2.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room3.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room4.jpg" class="img-fluid rounded" />
        </div>
      </div>

            {/* Close Row 5*/}

    </div>
  );
}
