import React from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import "./App.css";
//import Header from './Header';
import Homepage from "./Home-page";
import Aboutpage from "./About-page";
import BootstrapPage from "./bootstrap-page";
import Pricing from "./Price-page";
import Mappage from "./Map-page";
import Reviewpage from "./Review-page";

import FavoriteIcon from "@mui/icons-material/Favorite";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dentistpage from "./Dentist-page";
import Footer from "./Footer";

import { IconButton } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';

function App() {
  return (
    <div className="Mali">
      <BrowserRouter>
        <div
        className="navbar"
          // style={{
          //   background: "#c786d3",
          //   justifyContent: "start",
          //   alignItems: "center",
          //   textAlign: "center",
          //   height: "10vh",
          // }}
        >
          <Toolbar sx={{ flexWrap: "wrap" }}>
            

            <img src="img/logomdc.png"/> 



            <Typography
              variant="h4"  
              color="#fff"
              noWrap
              sx={{ flexGrow: 1, textAlign: "left",fontFamily: "Mali", paddingBottom: "1vh"  }} 
            >
              ศูนย์ทันตกรรมมีนบุรี
            </Typography>

            {/* <FavoriteIcon sx={{ mr: 2, color: "#fff" }} /> */}
            
            <div >
            <nav className="nav"
              style={{
                // padding: "2vh",
                fontSize: "130%",
              }}
            >
              <NavLink
                to="/"
                style={ ({ isActive }) => ({
                    color: isActive ? "#fff" : "#512da8",
                    padding: "1vh",
                  })
                }
              >
                
                  หน้าหลัก
              </NavLink>



              <NavLink
                to="/bootstrap"
                style={ ({ isActive }) => ({
                    color: isActive ? "#fff" : "#512da8",
                    padding: "1vh",
                  })
                }
              >
                
                บรรยากาศร้าน
              </NavLink>

              <NavLink
                to="/Pricing"
                style={ ({ isActive }) => ({
                    color: isActive ? "#fff" : "#512da8",
                    padding: "1vh",
                  })
                }
              >
                
                อัตราค่ารักษา
              </NavLink>

              <NavLink
                to="/dentist"
                style={ ({ isActive }) => ({
                    color: isActive ? "#fff" : "#512da8",
                    padding: "1vh",
                  })
                }
              >
                
                ทีมทันตแพทย์
              </NavLink>

              <NavLink
                to="/map"
                style={ ({ isActive }) => ({
                    color: isActive ? "#fff" : "#512da8",
                    padding: "1vh",
                  })
                }
              >
                
                ที่ตั้งคลินิก
              </NavLink>

              <NavLink
                to="/about"
                style={ ({ isActive }) => ({
                    color: isActive ? "#fff" : "#512da8",
                    padding: "1vh",
                  })
                }
              >
                
                เกี่ยวกับ MDC
              </NavLink>

              <NavLink
                to="/review"
                style={ ({ isActive }) => ({
                    color: isActive ? "#fff" : "#512da8",
                    padding: "1vh",
                  })
                }
              >
                
                รีวิวของทางร้าน
              </NavLink>



            </nav>
            </div>

          </Toolbar>
        </div>

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/bootstrap" element={<BootstrapPage />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/map" element={<Mappage />} />
          <Route path="/dentist" element={<Dentistpage />} />
          <Route path="/review" element={<Reviewpage />} />

        </Routes>
      </BrowserRouter>

      {/* <input type="button" value="ปุ่มลอย" class="btn"> 
      
      </input> */}
      {/* <IconButton href="https://lin.ee/flRWtGQ" target="_blank" class="btn">
        <FacebookIcon /> Line chat 
      </IconButton> */}

      <a href= "https://page.line.me/mdc.n9" class="btn4"> <img src="./img/icon_line.png" alt="LINE CHAT" style={{width: 42 ,  height: 42}}></img> </a> 

      <a href= "https://www.instagram.com/mdcdental.n9" class="btn2"> <img src="./img/icon_ig.png" alt="INSTAGRAM MDC" style={{width: 42 ,  height: 42}}></img> </a> 

      <a href= "https://www.facebook.com/profile.php?id=1000636313881" class="btn3"> <img src="./img/icon_facebook.png" alt="FACEBOOK MDC" style={{width: 42 ,  height: 42}}></img> </a> 

      <a href= "https://www.tiktok.com/@mdc_n9?_t=8g5ISNU7tUV&_r=1" class="btn"> <img src="./img/icon_tiktok.png" alt="TIKTOK" style={{width: 42 ,  height: 42}}></img> </a> 


      
      <Footer />
    </div>
  );
}

export default App;
