import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import Select from "react-select";
import { Padding } from "@mui/icons-material";



function screen() {
  if (window.innerWidth >= 900) {
    return "row";
  
  
  } else {
    return "column"; 
  }

}

//const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const theme1 = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Mali',
    },
  },
});


export default function Dentistpage() {
  const options = [
    { value: "ทันตแพทย์จัดฟัน", label: "ทันตแพทย์จัดฟัน" },
    { value: "ทันตแพทย์ทั่วไป", label: "ทันตแพทย์ทั่วไป" },
    { value: "ทันตแพทย์รักษารากฟัน", label: "ทันตแพทย์รักษารากฟัน" },
    { value: "ทันตแพทย์ทางด้านเด็ก", label: "ทันตแพทย์ทางด้านเด็ก" },
    { value: "ทันตแพทย์ใส่ฟัน", label: "ทันตแพทย์ใส่ฟัน" },

  ];
  const [selectedOption, setSelectedOption] = React.useState("");

  var handleChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedOption(selectedOption.value);
  };

  const [post, setPost] = React.useState([]);



  React.useEffect(() => {
    //axios.get("https://linebot.mdc-all.com/rep").then((data) => {
    let str = `https://webmdc-backend.mdc-all.com/profile?type=${selectedOption}`;
    axios.get(str).then((data) => {
      console.log(data);
      setPost(data?.data.recordset);
    });
  }, [selectedOption]);

  return (
    <>
      <ThemeProvider theme={theme1}>
        <CssBaseline />

        <Container sx={{ py: 4 }} maxWidth="md">
          <div className="App" style={{ paddingBottom: "2vh" }}>
            <Select
              options={options}
              //defaultValue={selectedOption}
              defaultValue={selectedOption}
              onChange={handleChange}
            />
          </div>

          <Grid container spacing={4}>
            {post.map((card) => (
              <Grid item key={card} xs={12} sm={12} md={12}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: screen,

                  }}
                >
                  <CardMedia
                    component="div"
                    sx={
                      {
                        // 16:9
                        //pt: "56.25%",
                        //pt: "120%",
                        //width: 200,
                        //height: 300,
                      }
                    }
                    //image={"https://linebot.mdc-all.com/upload/" + card.picture}
                    image={"https://linebot.mdc-all.com/upload/" + card.PICTURE}
                    style={{ width: 200, height: 220 }}
                  />

                  {/* <CardContent sx={{ flexGrow: 1 }} style={{ width: 200, height: 220 }}> */}
                  <CardContent sx={{ flexGrow: 1, width: 400 }}>

                  <Typography gutterBottom variant="h6" component="h5">
                      {card.PNAME +" " + card.FNAME + " " + card.LNAME}
                  </Typography>

                  <Typography >
                    {"ชื่อเล่น : " + card.NICKNAME}
                    </Typography>

                    <Typography gutterBottom variant="body1" component="h5">
                      ประวัติการศึกษา :
                    </Typography>

                    <ul style= {{listStyle: 'none'}} >
                    <li>
                      
                    <Typography gutterBottom variant="body1" component="h5">
                      {card.EDUCATION}
                    </Typography>


                    </li>
                  </ul>

                  <ul style= {{listStyle: 'none'}} >
                    <li>
                      

                    <Typography gutterBottom variant="body1" component="h5">
                      {card.EDUCATION2}
                    </Typography>


                    </li>
                  </ul>

                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

 
      </ThemeProvider>
    </>
  );
}

