import { Container } from '@mui/system'
import React from 'react'

const Aboutpage = () => { 
  return (
    <>
    <Container disableGutters  component="main" sx={{ pt: 8, pb: 6 }}> 

    
        {/* padding: "80px" */}
        {/* <div style={{ paddingBottom: "20vh", paddingTop: "5vh" ,paddingLeft: "20vh" , paddingRight: "20vh"}}> */}

          <h2 style={{textAlign: "center", color: "#773183" }}>เกี่ยวกับคลินิกทันตกรรม MDC</h2>

        <h5 style={{textAlign: "center",color: "#9289a3"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ศูนย์ทันตกรรม MDC ก่อตั้งเมื่อวันที่  25  พฤษภาคม  2551 ตั้งอยู่ใน  เลขที่  92  ถนนสีหบุรานุกิจ  แขวงมีนบุรี  เขตมีนบุรี  กทม.  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;มีจำนวนยูนิตทำฟัน  6  ยูนิต  เปิดให้บริการทุวัน  ตั้งแต่เวลา  09.00-19.00 น.  ให้บริการทางทันตกรรมทั้งผู้ป่วยในและผู้ป่วยภายนอกโรงพยาบาล  โดยทันตแพทย์ทั่วไป  และเฉพาะทางด้านสาขา  เช่นทันตกรรมประดิษฐ์  รักษารากฟัน  ทันตกรรมเด็ก  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ปริทันต์ รักษารากฟัน  ศัลยกรรม  และทันตกรรมจัดฟัน</h5> 

        <h3 style={{textAlign: "center", color: "#773183"}}> วิสัยทัศน์ </h3>           
        <h5 style={{ textAlign: "center",color: "#9289a3"}}>มุ่งเน้นการให้บริการทางทันตกรรมตามมาตรฐานวิชาชีพ เพื่อให้ประชาชนทุกคนมีสุขภาพช่องปากที่ดี
        </h5>

        <h3 style={{textAlign: "center", color: "#773183"}}> เป้าหมาย </h3>

        <h5 style={{ textAlign: "center",color: "#9289a3"}}> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทันตแพทย์และเจ้าหน้าที่ทุกคนมุ่งเน้นให้บริการด้านทันตกรรมแก่ผู้รับบริการทุกรายตามมาตรฐาน
        วิชาชีพทันตกรรม  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ด้วยเครื่องมือที่ทันสมัย โดยทันตแพทย์ผู้เชี่ยวชาญเฉพาะทาง  ด้วยความสะดวก  รวดเร็ว  คุณภาพครบถ้วน  มีมาตรฐานเดียวกัน  เกิดความประทับใจ  ปราศจากการติดเชื้อ</h5>



        

        {/* </div> */}

        </Container>

 

        


    </>
  )
}

export default Aboutpage