import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { CardMedia } from "@mui/material";

const promotion = [
  {
    title: "โปรโมชั่น1",
    price: "0",
    picture: "https://linebot.mdc-all.com/upload/promotion2990.png",
    description: [
      "ฟอกฟันขาว คือ กระบวนการที่ช่วยเพิ่มความขาวให้กับฟัน ด้วยการใช้ผลิตภัณฑ์ฟอกสีฟัน ไม่ว่าจะทำที่คลินิกโดยทันตแพทย์ ด้วยระบบ Cool Light LED หรือถ้าการฟอกสีฟันที่บ้านด้วยตัวเอง",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
  {
    title: "โปรโมชั่น2",
    subheader: "Most popular",
    price: "15",
    picture: "https://linebot.mdc-all.com/upload/pro2.jpg",
    description: [
      "การแก้ปัญหาการสูญเสียฟันแท้ที่หลุดไปด้วยการผ่าตัด ฝังโครงรากฟันเทียมเพื่อยึดติดกับกระดูกขากรรไกรของคนไข้ ตรงตำแหน่งที่สูญเสียฟันเเละรากฟันธรรมชาติไป ",
    ],
    buttonText: "Contact us",
    buttonVariant: "contained",
  },
  {
    title: "โปรโมชั่น3",
    price: "30",
    picture: "https://linebot.mdc-all.com/upload/promotion veeneer.png",
    description: [
      "เซรามิก วีเนียร์ (Ceramic Veneer) คือ แผ่นเซรามิกบางๆ ที่มีความแข็งแรงทนทาน สีสัน และความโปร่งแสงใกล้เคียงกับฟันธรรมชาติ ซึ่งทันตแพทย์จะทำการติดบนผิวหน้าฟัน เพื่อแก้ไขรูปร่าง สี และขนาดของฟันให้สวยงาม เรียงตัวได้สัดส่วน",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


const theme1 = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Mali',
    },
  },
});


export default function Homepage() {
  return (
    <>
      <ThemeProvider theme={theme1}>
        {/* <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        /> */}
        <CssBaseline />

        {/* Hero unit        disableGutters component="main"     */}
        <Container sx={{ pt: 8, pb: 4 }}>
          <div className="App" style={{ paddingBottom: "1vh" }}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="#773183"
              gutterBottom
            >
              ดูแลรักษาสุขภาพช่องปากของคุณที่ MDC
            </Typography>

            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="#773183"
              gutterBottom
            >
              เรามีทีมทันตแพทย์เฉพาะทางที่มีประสบการณ์พร้อมเครื่องมือที่ทันสมัย
            </Typography>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="#773183"
              gutterBottom
            >
              เพื่อรอยยิ้มที่สดใสของคุณกลับมาอีกครั้ง
            </Typography>

            {/* <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              component="p"
            >
              เรามุ่งมั่นที่จะให้คุณมีกลิ่นหายใจที่สดใสและมีรอยยิ้มที่สวยงามอีกครั้ง
              ที่ MDC
              คุณจะพบทีมแพทย์ที่มีประสบการณ์และความมุ่งมั่นในการดูแลสุขภาพช่องปากของคุณอย่างดีที่สุด
              ไม่ว่าคุณจะมาเพื่อการตรวจสุขภาพประจำปี การทำความสะอาดปาก
              หรือการรักษาทันตกรรมที่ทันสมัย
              ทีมของเราพร้อมให้บริการคุณอย่างเต็มที่ทุกวันคุณจะได้พบกับทีมแพทย์และผู้เชี่ยวชาญที่มีประสบการณ์และความมุ่งมั่นในการดูแลฟันของคุณให้เหมาะสมที่สุด
              ไม่ว่าคุณจะมาเพื่อการตรวจสุขภาพฟันประจำปี, การรักษาทันตกรรมทั่วไป,
              หรือบริการพิเศษ เราพร้อมที่จะดูแลคุณอย่าง.....
            </Typography> */}
          </div>
        </Container>
        {/* End hero unit */}

        <Container>
          <div class="row" >


            <div class="col-md-4 col-12 text-end">
              <img
                src="img/B1.png"
                class="img-fluid"
                width="150"
                height="200"
              />
            </div>

            <div class="col-md-4 col-12 text-center">
              <img
                src="img/B2.png"
                class="img-fluid"
                width="150"
                height="200"
              />
            </div>
            <div class="col-md-4 col-12 text-left">
              <img
                src="img/B3.png"
                class="img-fluid"
                width="150"
                height="200"
              />
            </div>
          </div>
        </Container>

        <Container component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {promotion.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardMedia
                    component="div"
                    sx={{
                      pt: "100%",
                      
                    }}
                    
                    image={tier.picture}
                    
                  />

                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      {/* <Typography component="h2" variant="h3" color="text.primary">
                      ฿{tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /ครั้ง 
                    </Typography> */}
                    </Box>
                    <Box>
                      {tier.description.map((line) => (
                        <Typography
                          component="h6"
                          variant="subtitle1"
                          // variant="h5"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="success"
                      href="https://lin.ee/flRWtGQ"
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

          <div class="row mt-3">
            <div class="text-center">
              <img
                src="./img/promotion5.jpg"
                class="rounded"
                width="800px"
                height="auto"
              />
            </div>
          </div>
        </Container>
      </ThemeProvider>
    </>
  );
}
