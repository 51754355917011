// import React from 'react'

// export const AboutPage = () => {
//   return (
//     <div>AboutPage</div>
//   )
// }

import React from "react";
//import { Button, Card, Row, Col, Container, Navbar, Nav  } from 'react-bootstrap'

export default function BootstrapPage() {
  return (
    <div class="container">
      {/* <ul class="nav">
            <li class="nav-item"><a class="nav-link" href="index.html">หน้าหลัก</a></li>
            <li class="nav-item"><a class="nav-link" href="price.html">อัตราค่ารักษา</a></li>
            <li class="nav-item"><a class="nav-link" href="#">Download</a></li>
            <li class="nav-item"><a class="nav-link" href="about.html">เกี่ยวกับคลินิก</a></li>
       </ul> */}
      <div class="row">

        <h1 style={{textAlign: "center", paddingTop: "5vh" , color: "#773183",}}> บรรยากาศหน้าร้าน </h1>  
      </div>

      {/* เพิ่มรูป
        <div class="row"> 
            <div class="col">
               
                <img src="img/logomdc.png" class="img-fluid" />
            </div>
        </div>  */}

      <div class="row mb-2">
        <div class="col">
          {/* <img src='https://mdbootstrap.com/img/new/standard/city/041.webp' /> */}
          <img src="img/pic4.jpg" class="img-fluid" />
        </div>
      </div>

      {/* Close Row 2 */}

      {/* Start Row 3 */}
      <div class="row">
        <div class="col-md-4 col-12">
          <img src="img/room2.jpg" class="img-fluid" />

          <div class="mt-3">
            <h2>3D Digital X-ray ดียังไง?</h2>
          </div>

          <p>
            เครื่อง X-RAY ฟัน ระบบ 3 มิติ
            สำหรับการรักษาทางทันตกรรมเครื่องเอ็กซเรย์ฟันระบบ 3 มิติ
            มีประโยชน์อย่างมากในขั้นตอนการรักษาทางทันตกรรมโดยเฉพาะการทำงานของเครื่องที่จะช่วยให้ทันตแพทย์ได้ภาพเอกซเรย์ของฟันที่เห็นรายละเอียดชัดเจน
            ด้วยระบบการสแกนแบบพาโรนามา360 องศา เพียงสแกนครั้งเดียว
            ใช้เวลาไม่นานก็จะแสดงผลที่ดีที่สุด
            ออกมาเป็นภาพที่ต้องการและได้อย่างถูกต้อง
            ซึ่งผลลัพธ์ออกมาจะเป็นภาพที่มีความละเอียดสูง สามารถนำภาพไปวินิจฉัย
            วางแผนการรักษา และประเมินสภาวะทางสุขภาพของช่องปากคุณได้ดีขึ้น
            เครื่องเอกซเรย์ 3 มิติมีประโยชน์กับการรักษาด้านทันตกรรมจัดฟัน
            ทันตกรรมรากเทียม การผ่าตัดขากรรไกร การรักษาด้านทันตกรรมอื่นๆ
          </p>
        </div>
        <div class="col-md-4 col-12">
          <img src="img/room6.jpg" class="img-fluid" />


          <div class="mt-3">
          <h2>เครื่องนึ่งฆ่าเชื้อทาง</h2>
          <h2>ทันตกรรมมีประโยชน์ยังไง?</h2>
          </div>

          <p>
            เป็นเครื่องมือที่ใช้สำหรับฆ่าเชื้อโรค จุลินทรีย์ขนาดเล็ก
            โดยใช้ไอน้ำร้อนและอยู่ในระบบปิดเพื่อให้เกิดแรงดันอากาศสูง
            ทำให้เครื่องมือ อุปกรณ์ ของที่ผ่านการอบไอน้ำที่ความดันสูงแล้ว
            อยู่ในสภาพปราศจากเชื้อ
            จึงมักใช้เครื่องนี้ในการนึ่งฆ่าเชื้อของเสียทางชีวภาพเพื่อฆ่ากำจัด
            ต้นต่อของเชื้อโรค เครื่อง Autoclave
            ยังสามารถใช้ทำความสะอาดภาชนะได้เป็นอย่างดี ก่อนจะนำมาใช้งานอีกครั้ง
            ส่วนการใช้งานเครื่อง Autoclave
            นั้นควรมีการทดสอบเครื่องอย่างสม่ำเสมอเพื่อให้สามารถใช้งานเครื่อง
            Autoclave
          </p>
        </div>
        <div class="col-md-4 col-12">
          <img src="img/room7.jpg" class="img-fluid" />

          <div class="mt-3">
          <h2>ทำไมควรมี</h2>
          <h2>ทีมทันตแพทย์เฉพาะทาง</h2>
          </div>
          <p>
            ที่ MDC@รพ.นวมินทร์9 เรามีทีมทันตแพทย์เฉพาะทางที่ได้รับการฝึกฝน
            และมีประสบการณ์มากมายเพื่อให้การรักษาและดูแลสุขภาพช่องปาก
            เป็นไปอย่างมีประสิทธิภาพ โดยทันตแพทย์จะส่งต่อการรักษาที่ต่อเนื่อง
            ไปยังทันตแพทย์ที่เชี่ยวชาญในแต่ละด้าน ได้แก่ ทีมทันตแพทย์จัดฟัน
            ทีมทันตแพทย์รักษารากฟัน ทีมทันตแพทย์ด้านเด็ก ทีมทันตแพทย์ใส่ฟัน
          </p>
        </div>
      </div>

      {/* Close Row 3 */}

      {/* Start Row 4 */}
      <div class="row">
        <div class="col-md-6 col-12">
          <img src="img/room1.jpg" class="img-fluid" />

          <div class="mt-3">

          <h2>บรรยากาศห้องทำฟัน</h2>
          </div>


          <p>
            ทางคลีนิกทันตกรรมของเราได้ทุ่มเทในการสร้างบรรยากาศที่เป็นกันเองและน่าประทับใจในทุกห้องทำฟันของเรา
            มีการออกแบบที่ทันสมัย ห้องทำฟันส่วนตัว โฉมใหม่ สะอาดสวย สว่าง
            และอบอุ่น บรรยากาศสบายๆ
            การให้บริการที่ใส่ใจที่จะทำให้คุณรู้สึกมั่นใจและสบายใจ
          </p>
        </div>
        <div class="col-md-6 col-12">
          <img src="img/room3.jpg" class="img-fluid" />

          <div class="mt-3">
          <h2>ทางร้านเรามีมุมสนุกของเด็กๆ</h2>
          </div>


          <p>
            การสร้างประสบการณ์ทันตกรรมที่สนุกสำหรับเด็กเป็นสิ่งสำคัญในการส่งเสริมการดูแลสุขภาพช่องปากในวัยเรียนตัวเอง
            ที่คลีนิกทันตกรรมของเรา
            เราร่วมมือกับทีมทันตแพทย์และผู้เชี่ยวชาญที่มีประสบการณ์ในการทำให้เด็กๆ
            รู้สึกสนุกและลดความกังวลในการรับการรักษาทันตกรรม ด้วยมุมสนุกต่าง ๆ
            ที่เตรียมไว้ที่คลีนิกของเรา
          </p>
        </div>
      </div>
      {/* Close Row 4 */}

      {/* Start Row 5 */}

      <div class="row">
        <div class="col-3 mt-2">
          <img src="img/room1.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room2.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room3.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room4.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room1.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room2.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room3.jpg" class="img-fluid rounded" />
        </div>
        <div class="col-3 mt-2">
          <img src="img/room4.jpg" class="img-fluid rounded" />
        </div>
      </div>

      {/* Close Row 5*/}
    </div>
  );
}
