import * as React from "react";
import { createTheme, ThemeProvider} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Select from "react-select";
import "./App.css";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const theme1 = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Mali',
    },
  },
});



export default function Pricing() {
  const options = [
    { value: "ทันตกรรมทั่วไป", label: "ทันตกรรมทั่วไป" },
    { value: "ศัลยกรรมช่องปาก", label: "ศัลยกรรมช่องปาก" },
    { value: "งานปริทันต์", label: "งานปริทันต์" },
    { value: "ทันตกรรมบูรณะ", label: "ทันตกรรมบูรณะ" },
    { value: "งานรักษารากฟัน", label: "งานรักษารากฟัน" },
    { value: "ฟันปลอมถอดได้", label: "ฟันปลอมถอดได้" },
    { value: "ครอบฟัน", label: "ครอบฟัน" },
    { value: "รากฟันเทียม", label: "รากฟันเทียม" },
    { value: "จัดฟัน", label: "จัดฟัน" },
    { value: "ฟอกสีฟัน", label: "ฟอกสีฟัน" },
    { value: "ทันตกรรมบดเคี้ยว", label: "ทันตกรรมบดเคี้ยว" },
    { value: "ค่าบริการอื่นๆ", label: "ค่าบริการอื่นๆ" },
    { value: "ทันตกรรมเด็ก", label: "ทันตกรรมเด็ก" },
  ];

  const [selectedOption, setSelectedOption] = React.useState("");

  var handleChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedOption(selectedOption.value);
  };

  const [post, setPost] = React.useState([]);

  React.useEffect(() => {
    let str = `https://webmdc-backend.mdc-all.com/pricing?type=${selectedOption}`;
    axios.get(str).then((data) => {
      console.log(data);
      setPost(data?.data.recordset);
    });
  }, [selectedOption]);

  const getColor = (quantity) => {
    if (quantity === "head") return "#b39ddb";
    return "";
  };

  return (
    <>
      <ThemeProvider theme={theme1}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />

        {/* Hero unit */}
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 1, fontFamily: "Mali" }}
        >


          <Typography
            component="h3"
            variant="h3"
            align="center"
            color="#7d6d99"
            gutterBottom
          >
            อัตราค่ารักษา
          </Typography>
        </Container>
        {/* End hero unit */}

        <Container sx={{ py: 3 }} maxWidth="md">
          <Select
            options={options}
            //defaultValue={selectedOption}
            defaultValue={selectedOption}
            onChange={handleChange}
          />
        </Container>

        <Container maxWidth="md" component="main">
          <TableContainer component={Paper}>
            {/* minWidth: 650  */}
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow
                  // sx={{
                  //   backgroundColor: "#7d6d99", }}

                  sx={{
                    "& th": {
                      color: "white",
                      backgroundColor: "#7d6d99"
                    }
                  }}

                >
                  <TableCell 

                  align="center"

                  >รายการ
                  </TableCell>

                  <TableCell align="right">ราคา(บาท)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {post.map((row) => (
                  <TableRow
                    key={row.ID}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: getColor(row.TYPE),
                    }}
                  >
                    <TableCell align="left">{row.TREATMENT}</TableCell>
                    <TableCell align="right">{row.PRICE}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* // <tr>

            //   <td> {tier.TREATMENT} </td>
            //   <td> {tier.PRICE} </td>
            // </tr>

              // <Typography component="h6" variant="h6" color="text.primary">
              //   {tier.ID} {tier.TREATMENT} {tier.PRICE}
              // </Typography> */}
        </Container>
      </ThemeProvider>
    </>
  );
}
