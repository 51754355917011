//racfe
import React from 'react'

const Footer = () => {
  return (
    <>
    
    <hr />
    <h4 style={{textAlign: "center", color: "#773183" }}>เวลาทำการ</h4>
    <h6 style={{textAlign: "center", color: "#773183" }}>ทุกวัน : 9:00 น. - 19:00 น.</h6>

    <h4 style={{textAlign: "center", color: "#773183" }}>ติดต่อเรา</h4>
    <h6 style={{textAlign: "center", color: "#773183" }}>โทรศัพท์: 02-518-2211, 089-887-9988</h6>

    </>
  )
}

export default Footer