import { CenterFocusWeakTwoTone } from "@mui/icons-material";
import { Container } from "@mui/material";
import "./App.css";

function Mappage() {
  return (


<Container className="App" disableGutters  component="main" sx={{ pt: 8, pb: 6 }}> 


    {/* // <div className="App" style={{ paddingBottom: "30vh", paddingTop: "5vh" ,paddingLeft: "20vh" , paddingRight: "20vh"}}> */}


        <h1  style={{textAlign: "center", color: "#773183", fontFamily: "Mali"}}> แผนที่คลินิกทันตกรรม MDC</h1>
        <h3>      </h3>
      
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.4442486500525!2d100.71828787704422!3d13.812341851998337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d6517f348f1fd%3A0xa279f5d7fddde7db!2z4LiE4Lil4Li04LiZ4Li04LiB4LiX4Lix4LiZ4LiV4LiB4Lij4Lij4LihIOC5gOC4reC5h-C4oeC4lOC4teC4i-C4tSDguYHguK3guJcg4LiZ4Lin4Lih4Li04LiZ4LiX4Lij4LmMIDk!5e0!3m2!1sth!2sth!4v1694267327929!5m2!1sth!2sth"
        width="500"
        height="300"
       
        style={{border: "0"}}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

        <h1 style={{textAlign: "center", color: "#773183"}}> ที่อยู่ </h1> 
        <h5 style={{textAlign: "center", color: "#9289a3"}} > 92  ถ.สีหบุรานุกิจ  แขวงมีนบุรี  เขตมีนบุรี  กทม.  10510 </h5>
        <h5 style={{textAlign: "center", color: "#9289a3"}}> โทร  02-5182211,  089-8879988 </h5>
        <h5 style={{textAlign: "center", color: "#9289a3"}}> email: mdc-n9@hotmail.com  </h5>



        <h1 style={{textAlign: "center", color: "#773183"}}> รถประจำทางที่ผ่าน </h1>   
        <h5 style={{textAlign: "center", color: "#9289a3"}}> สาย  ปอ. 26,  27,  501,  502,  514,  519,  549,  รถกรุงเทพฉะเชิงเทรา
        สายธรรมดา    26,  27,  58,  96,  113,  131,  151,  517 </h5>
        <h5 style={{textAlign: "center", color: "#9289a3"}}>และ รถตู้ทุกคันที่มีชุมสายอยู่ตลาดมีนบุรี </h5>
    {/* </div> */}

    </Container>
  );
}

export default Mappage;


